
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { FeedBackInfo } from '@/types/visitor'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'

@Component({
  name: 'GuideCommentDetail',
  filters: {
    imgListFormat (list: Array<FileInfo>) {
      let imgList: Array<string> = []
      if (list && list.length > 0) {
        imgList = list.map(item => {
          return item.filePrefix + item.fileUrl
        })
      }
      return imgList || []
    }
  }
})
export default class extends Vue {
  private detailInfo: FeedBackInfo | null = null
  private LocationImg = require('@/assets/icon/dispatch/icon_map_shijian.svg')

  get taskId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.taskId) {
      this.getDetail()
    }
  }

  getDetail () {
    this.$axios.get(this.$apis.visitor.selectCitizenFeedbackById, {
      id: this.taskId
    }).then((res: FeedBackInfo) => {
      this.detailInfo = res
      this.loadMap(Number(this.detailInfo.longitude || ''), Number(this.detailInfo.latitude || ''))
    })
  }

  loadMap (lng: number, lat: number) {
    this.$nextTick(() => {
      if (lng && lat) {
        AMapLoader.load({
          key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: [] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then(() => {
          const mapContent = new AMap.Map('mapContent', {
            center: [lng, lat],
            zoom: 15
          })
          this.addMarker(mapContent, lng, lat)
        }).catch(e => {
          this.$message({ type: 'error', message: '地图加载失败：' + JSON.stringify(e) })
        })
      }
    })
  }

  addMarker (map: AMap.Map, lng: number, lat: number) {
    const marker = new AMap.Marker({
      map: map,
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-20, -40),
      icon: this.LocationImg,
      draggable: true
    })
    map.add(marker)
  }
}
